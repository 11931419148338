/* You can add global styles to this file, and also import other style files */
@import 'episode-solutions-app-theme.scss';

html, body { height:100%; padding: 0; margin: 0; font-family: 'Roboto', sans-serif;} 

/* Need this in order to position an "X" in the top right of the modals */
.cdk-overlay-pane.popupModal {
    position: relative !important;
}

.modal-close-button {
    /* Position: absolute will need to be added as an inline style, otherwise angular injects its own classes that will override this */
    top: 0px; 
    right: 0px;
    cursor: pointer;
}

.form-field-no-bottom .mat-form-field-wrapper{
    margin-bottom: -1.25em !important;
}

.page-title {
    height: 48px; 
    font-weight: bold; 
    font-size: 24px;
}

.page-wrapper {
  margin-left: 16px; 
}

.page-divider {
  margin-right: 16px; 
}

.upload-file-icon {
  background: url('../src/assets/image/uploadImage.png');
  height: 24px;
  width: 24px;
  display: block;
  background-size: cover;
  background-position: center;
  cursor: pointer;
}