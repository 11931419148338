@use '~@angular/material' as mat;
@import '~@angular/material/theming';
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
:root { --primary-main: #3a8240; }
:root { --primary-dashboardIcon: #CBE8CD; }
:root { --primary-menuButton: #E9F6EB; }

:root { --accent-menuButtonInactive: #F1E6EF; }
:root { --accent-medium: #DDC0D8; }

$mat-primary: (
  main: var(--primary-main),
  lighter: #6ab26c,
  darker: #005516,
  menuButton: var(--primary-menuButton),
  dashboardIcon: var(--primary-dashboardIcon),
  contrast : (
    main: $light-primary-text,
    lighter: $light-primary-text,
    darker: $dark-primary-text,
  )
);

$mat-accent: (
  main: #823a7c,
  lighter: #b368ab,
  medium: var(--accent-medium),
  darker: #530950,
  menuButtonInactive: var(--accent-menuButtonInactive),
  contrast : (
    main: $light-primary-text,
    lighter: $light-primary-text,
    darker: $dark-primary-text,
  )
);

$episode-solutions-app-primary: mat.define-palette($mat-primary, main, lighter, darker);
$episode-solutions-app-accent:  mat.define-palette($mat-accent, main, lighter, darker);

// The warn palette is optional (defaults to red).
$episode-solutions-app-warn:    mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as `color` or `typography`.
$episode-solutions-app-theme: mat.define-light-theme((
  color: (
    primary: $episode-solutions-app-primary,
    accent: $episode-solutions-app-accent,
    warn: $episode-solutions-app-warn,  
  )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($episode-solutions-app-theme);